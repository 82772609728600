<template>
  <div>
    <transition-group tag="div" class="tabs-content tabs-offset" v-if="posts.length > 0">
      <article class="news-card" v-for="post in posts" :key="post.id">
        <header class="news-card-header news-card__header">
          <h2 class="news-card__title" v-if="$i18n.locale === 'ru'">{{ post.name_ru }}</h2>
          <h2 class="news-card__title" v-else>{{ post.name }}</h2>
          <div class="news-card__category">
                      <span v-if="categoryId === 1">
                        {{ $t("academy.academy") }}
                      </span>
                      <span v-if="categoryId === 2">
                        {{ $t("academy.news") }}
                      </span>
            <span>{{ formattedDate(post.created) }}</span>
          </div>
        </header>
        <div class="news-card__image">
          <img v-if="$i18n.locale === 'ru'" :src="post.img_ru" alt="preview"/>
          <img v-else :src="post.img" alt="preview"/>
        </div>
        <div class="news-card-info">
          <p class="news-card-info__desc" v-if="$i18n.locale === 'ru'">{{ post.excerpt_ru }}</p>
          <p class="news-card-info__desc" v-else>{{ post.excerpt }}</p>
        </div>
        <router-link class="card-link" :to="{ name: 'showPost', params: { postId: post.id } }">
          <academy-button-read :text="$t('academy.readMore')"/>
        </router-link>
      </article>
    </transition-group>
    <div v-else>
            <UiLoader theme="block" :loading="true" />
    </div>
    <button class="academyButtonLoadPosts" @click.stop="fetchPostsButton">{{$t('academy.add_more')}}</button>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import AcademyButtonRead from "@/components/Academy/AcademyButtonRead.vue"

export default {
  name: "AcademyTabsContent",
  components: {AcademyButtonRead},
  props: {
    categoryId: {
      required: true,
      type: Number,
    },
    categoryName: {
      type: String,
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    categoryId() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters({
      posts: "allSpecificPosts",
    }),
    postsLength() {
      return this.posts.length + 10
    },
  },
  methods: {
    fetchData() {
      if (this.categoryId === 1) {
        this.$store.dispatch("fetchAcademyPosts")
      } else {
        this.$store.dispatch("fetchNewsPosts")
      }
    },
    fetchPostsButton() {
      if (this.categoryId === 1) {
        this.$store.dispatch("fetchAcademyPosts", this.postsLength )
      } else {
        this.$store.dispatch("fetchNewsPosts", {limit: this.postsLength})
      }
    },
    /**
     * Get Date By Timestamp
     *
     * @returns {string}
     * @param date
     */
    formattedDate(date) {
      return this.$moment(date * 1000).format("DD MMMM YYYY")
    },
  },
}
</script>
<style lang="scss" scoped>
.academyButtonLoadPosts {
  transition: .2s all ease-in;
  margin-left: 43%;
  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #FFFFFF;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  border-radius: 100px;
  &:hover {
    opacity: .6;
  }
  @media (max-width: 768px) {
    margin-left: 40%;
  }
}
</style>
