<template>
  <div class="news-aside" v-if="posts.length" :key="language">
    <div class="news-aside__head">
      <h3 class="news-aside__title" v-if="categoryId === 1">{{ $t("academy.news") }}</h3>
      <h3 class="news-aside__title" v-if="categoryId === 2">{{ $t("academy.academy") }}</h3>
    </div>
    <div class="news-aside__blocks">
      <div class="news-aside__items">
        <div class="news-aside__item" v-for="post in posts" :key="post.id" @click="showPost(post)">
          <h4 class="news-aside__item-title" v-if="$i18n.locale === 'ru'">{{ post.name_ru }}</h4>
          <h4 class="news-aside__item-title" v-else>{{ post.name }}</h4>
          <p class="news-aside__item-desc" v-if="$i18n.locale === 'ru'">
            {{ post.excerpt_ru }}
          </p>
          <p class="news-aside__item-desc" v-else>
            {{ post.excerpt }}
          </p>
          <span class="news-aside__item-date">{{ formattedDate(post.created) }}</span>
        </div>
      </div>
    </div>
    <router-link
        v-if="categoryId === 1"
        :to="{ name: 'showCategory', params: { id: '2' } }"
        class="news-aside__all"
    >
      {{ $t("academy.showAllNews") }}
    </router-link>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n"
import {mapGetters} from "vuex"

export default {
  name: "AcademyInformationTabsContent",
  mounted() {
    this.fetchData()
  },
  props: {
    categoryId: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters({
      posts: "allNewsPosts",
    }),
    language() {
      return i18n.locale
    },
  },
  methods: {
    fetchData() {
      if (this.categoryId === 1) {
        this.$store.dispatch("onlyNewfetchNewsPosts", {limit: 3, onlyNews: true})
      } else {
        this.$store.dispatch("onlyNewfetchAcademyPosts", 3)
      }
    },
    showPost(post) {
      this.$router.push({name: "showPost", params: {postId: post.id}})
    },
    formattedDate(date) {
      return this.$moment(date * 1000).fromNow()
    },
  },
  watch: {
    categoryId() {
      this.fetchData();
    },
  },
}
</script>
<style lang="scss" scoped>
.news-aside__item {
  border: 1px solid transparent;
  transition: border 0.15s ease-out;
  cursor: pointer;

  &:hover {
    transition: border 0.15s ease-out;
    border: 1px solid #fedd00;
  }
}
</style>
